html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  background: #151619;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .centered {

  }
}